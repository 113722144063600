<template>
  <div>
      <mdb-navbar expand="large" position="top" dark :color="'ar-bg-trans'">
      <mdb-navbar-brand href="#" router to="/">
        <img src="/img/1.png" class="arvore-logo">  
      </mdb-navbar-brand>
      <mdb-navbar-toggler>
        <mdb-navbar-nav right>
          <mdb-nav-item to="/about-us" :style="color !== 'trans' ? 'background-color: transparent;' : ''" href="#">Acerca de</mdb-nav-item>
          <mdb-nav-item to="/team" :style="color !== 'trans' ? 'background-color: transparent;' : ''" href="#">Equipo</mdb-nav-item>
          <mdb-nav-item to="/services" :style="color !== 'trans' ? 'background-color: transparent;' : ''" href="#">Servicios</mdb-nav-item>
          <mdb-nav-item to="/clients" :style="color !== 'trans' ? 'background-color: transparent;' : ''" href="#">Proyectos</mdb-nav-item>
          <mdb-nav-item to="/mission-vision" :style="color !== 'trans' ? 'background-color: transparent;' : ''" href="#">Misión y visión</mdb-nav-item>
          <mdb-nav-item to="/gallery" :style="color !== 'trans' ? 'background-color: transparent;' : ''" href="#">Galería</mdb-nav-item>
          <mdb-nav-item @click="$store.commit('_toggleContactModal')" :style="color !== 'trans' ? 'background-color: transparent;' : ''" href="#">Contacto</mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>
    <ContactModal />
  </div>
</template>
<script>
  import { mdbNavbar, mdbNavbarBrand, mdbNavbarToggler, mdbNavbarNav, mdbNavItem, mdbIcon  } from 'mdbvue';
  import ContactModal from "./ContactModal";
  export default {
    props: ["color"],
    name: 'NavbarPage',
    components: {
      mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
      mdbNavItem,
      mdbIcon,
      ContactModal
    }
  }
</script>
<style>

    .arvore-logo {
        max-width: 150px !important; 
        margin: 0px !important;
        margin-left: 20px !important;
        margin-bottom: 2px !important;
    }

    .ar-bg-trans {
      background-color: white !important;
    }
    
    .arv-bg {
        background-color:  #11619d;
    }
    
    .navbar {
      -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0.16), 0 0 0 0 rgba(0,0,0,0.12) !important;
      box-shadow: 0 0 0 0 rgba(0,0,0,0.16), 0 0 0 0 rgba(0,0,0,0.12) !important;
      opacity: 0.6 !important;
    }

    .nav-item {
      font-weight: 800;
      font-family: Raleway;
    }

    a.nav-link {
       color: #11619d !important;
    }

    .company-name {
      display: inline-block;
      font-family: Raleway;
      font-weight: 600;
      font-size: 1rem;
      padding-left: 0.5rem;
    }
    
    .ar-slogan {
      padding-bottom: 0;
      margin-bottom: 0;
      font-size: 0.6rem;
      text-align: center;
    }

    .ar-slogan-back {
      background-color: grey !important;
      opacity: .8;
      border-radius: 2px;
    }

    .navbar-brand img {
      max-width: 50px;
      height: auto; 
      margin: 20px;
    }

    @media (max-width: 1199.98px) {

      .navbar-brand img {
        max-width: 40px;
      }

    }

    @media (max-width: 991.98px) {

      .navbar-brand img {
        max-width: 35px;
      }

      .nav-item:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .nav-item:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

    }
 
    @media (max-width: 767.98px) {
      .nav-item {
        font-size: 12px;
      }

      .navbar-brand img {
        margin: 10px;
      }

      .company-name {
        font-weight: 400;
        font-size: 1rem;
        padding-top: 1rem;
      }


      .company-name-home {
        font-weight: 400;
        font-size: 1rem;
        padding-top: 1rem;
      }

    }

      
    @media (max-width: 575.98px) { 
    
    }

    .navbar-toggler-icon {
        cursor: pointer;
        background-image: url("/img/burguer.svg") !important;
    }

  </style>