<template>
    <div>
        <mdb-container>
            <mdb-modal :show="$store.state.showContactModal" @close="closeContactModal">
              <mdb-modal-header class="text-center" style="background-color: #11619d; color: white;">
                  <mdb-modal-title tag="h4" bold class="w-100">Contacto</mdb-modal-title>
              </mdb-modal-header>
              <mdb-modal-body class="mx-3">
                  <p style="color: grey;">Deje un mensaje para nosotros y con gusto le atenderemos a la brevedad posible.</p>
                  <validation-error v-bind:validation="$v.name"></validation-error>
                  <mdb-input label="Escriba su nombre" v-model="name" icon="user fa" iconClass="grey-text"/>
                  <validation-error v-bind:validation="$v.email"></validation-error>
                  <mdb-input label="Escriba su email" icon="envelope fa" v-model="email" type="email" iconClass="grey-text"/>
                  <validation-error v-bind:validation="$v.phone"></validation-error>
                  <mdb-input label="Escriba su teléfono" icon="phone fa" v-model="phone" type="text" iconClass="grey-text"/>
                  <label class="form-label" style="color: #9e9e9e; font-weight: bold;">Servicios de interés</label>
                  <div class="input-group">
                    <div class="ar-checkbox">
                        <input type="checkbox" v-model="selectedServices" id="sbn" value="Soluciones Basadas en la Naturaleza (NBS)"/>
                        <label for="sbn"><span class="check-custom"></span><p>Soluciones Basadas en la Naturaleza (NBS)</p></label>
                    </div>  
                    <div class="ar-checkbox">
                        <input type="checkbox" v-model="selectedServices" id="pac" value="Proyectos de Acción Climática"/>
                        <label for="pac"><span class="check-custom"></span><p>Proyectos de Acción Climática</p></label>
                    </div>  
                    <div class="ar-checkbox">
                        <input type="checkbox" v-model="selectedServices" id="estudios" value="Estudios Ambientales en el Sector Energético"/>
                        <label for="estudios"><span class="check-custom"></span><p>Estudios Ambientales en el Sector Energético</p></label>
                    </div>  
                    <div class="ar-checkbox">
                        <input type="checkbox" v-model="selectedServices" id="gestion" value="Gestión Regulatoria Ambiental"/>
                        <label for="gestion"><span class="check-custom"></span><p>Gestión Regulatoria Ambiental</p></label>
                    </div>  
                    <div class="ar-checkbox">
                        <input type="checkbox" v-model="selectedServices" id="reno" value="Tecnologías Renovables"/>
                        <label for="reno"><span class="check-custom"></span><p>Tecnologías Renovables</p></label>
                    </div>  
                  </div>
                  <label class="form-label" style="color: #9e9e9e; font-weight: bold;">Comentarios</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <i class="fa fa-pencil-alt prefix"></i>
                      </span>
                    </div>
                    <textarea style="outline: 0;" class="form-control" id="exampleFormControlTextarea1" rows="5" v-model="comments"></textarea>
                  </div>
                  <validation-error v-bind:validation="$v.comments"></validation-error>
              </mdb-modal-body>
              <mdb-modal-footer center>
                <button @click="submitContact" v-if="!sendingMessage" class="btn btn-arvore">Enviar<mdb-icon icon="paper-plane fa" class="ml-1"/></button>
                <h4 class="animated fadeIn infinite" v-if="sendingMessage">Enviando...</h4>
              </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal :show="sent" @close="closeSentModal">
              <mdb-modal-header class="text-center" style="background-color: rgb(19, 64, 77); color: white;">
                  <mdb-modal-title tag="h4" bold class="w-100">MENSAJE ENVIADO</mdb-modal-title>
              </mdb-modal-header>
              <mdb-modal-body class="mx-3">
                <h4>Hemos recibido sus datos con éxito, por favor espere mientras nos comunicamos con usted a través de los datos que nos dejó.</h4>
              </mdb-modal-body>
              <mdb-modal-footer center>
                <button @click="closeSentModal" class="btn btn-arvore">Cerrar<mdb-icon icon="paper-plane fa" class="ml-1"/></button>
              </mdb-modal-footer>
            </mdb-modal>
        </mdb-container>
    </div>
</template>
<script>
  import { mdbContainer,  mdbModal, mdbModalHeader, mdbModalBody, mdbInput, mdbModalFooter, mdbModalTitle, mdbIcon } from 'mdbvue';
  //import ContactModal from "./ContactModal";   
  import { required, email } from "vuelidate/lib/validators";

  import ValidationError from "./ValidationError";

  export default {
    props: ["shown"],
    name: 'ContactModal',
    components: {
      mdbContainer,     
      //mdbBtn,
      mdbModal,
      mdbModalHeader,
      mdbModalBody,
      mdbInput,
      mdbModalFooter,
      mdbModalTitle,
      mdbIcon,
      ValidationError
    },
    validations: {
      name: {
        required
      },
      phone: {
        required
      },
      email: {
        required,
        email
      },
      comments: {
        required
      }
    },
    data() {
      return {
        header: false,
        selectedServices:[],
        name: "",
        email: "",
        phone: "",
        comments: "",
        sendingMessage: "",
        sent: false
      }
    },
    methods: {
      async submitContact(){
        this.$v.$touch();
        if (!this.$v.$invalid) {        
          this.sendingMessage = true;  
          try {
            let response = await this.$store.dispatch("contactArvore", {
              name: this.name,
              phone: this.phone,
              email: this.email,
              comments: this.comments,
              services: this.selectedServices
            });
            if(response.data == "OK"){
              this.sendingMessage = false;
              this.sent = true;
              this.closeContactModal();
            }
          } catch(e) {
            alert("ERROR! Contacte a soporte técnico.");
          }
        }

      },
      resetContactForm() {
        this.name = null;
        this.phone = null;
        this.email = null;
        this.comments = null;
        this.selectedServices = [];
        this.$v.$reset();
      },
      closeContactModal(){
        this.resetContactForm();
        this.$store.commit('_toggleContactModal');
      },
      closeSentModal(){
        this.sent = false;
      }
    }
  }
</script>
<style>
  .color-arvore {
    color: #11619d !important;
  }
  .md-form {
    margin-top: 0;
    margin-top: 0;
  }
</style>