<template>
    <div v-if="show" class="text-danger">
        <div v-for="m in messages" v-bind:key="m">{{ m }}</div>
    </div>
</template>
<script>
    export default {
        props: ["validation", "message", "type"],
        computed: {
            show() {
                return this.validation.$dirty && this.validation.$invalid
            },
            messages() {
                let messages = [];
                if(this.validation.$dirty) {
                    if(this.hasValidationError("required")) {
                        if(this.message && this.type === 'required'){
                            messages.push(this.message);
                        } else {
                            messages.push("Introduzca el campo solicitado"); 
                        }
                    } else if (this.hasValidationError("email")) {
                        messages.push("Introduzca un email válido");
                    } else if(this.hasValidationError("alphaOnly")){
                        messages.push("Solo se permite introducir caracteres del alfabeto");
                    } else if(this.hasValidationError("minLength")){
                        messages.push("Se requiere introducir al menos 7 caracteres");
                    } else if(this.hasValidationError("maxLength")){
                        messages.push("Solo se permite introducir maximo 60 caracteres");
                    } 
                }
                return messages;
            }
        },
        methods: {
            hasValidationError(type) {
                return this.validation.$params.hasOwnProperty(type) && !this.validation[type];
            }
        }
    }
</script>